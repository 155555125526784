<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import ModalButtons from './Components/Modal-Menue.vue'

// local variables
const editDialog = ref(false)
const BLOCK_NAME = "divider_block";

// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    workflow_id: { type: String, required: false },
    image_library: { type: Object, required: false }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const json_divider = ref(props.data.json_divider)
const template = ref(BLOCK_NAME)

// functions
const edit_block = () => {
    editDialog.value = true
}

const save_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "json_divider": json_divider.value

            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {

    const cb = {
        "template_data": {
            "template": template.value,
            "json_divider": ""
        },
        "action": "add",
        "index": props.index
    }
    emits('blockCallback', cb)
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-code" name="Divider" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" name="Divider Block">
        <Divider />
        <div class="relative my-2">
            <div class="absolute inset-0 flex items-center" aria-hidden="true">
                <div class="w-full border-t border-gray-300"></div>
            </div>
            <div class="relative flex justify-center">
                <span class="bg-white px-2 text-gray-500">
                    <svg class="h-5 w-5 text-gray-500" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                        <path d="M10.75 4.75a.75.75 0 00-1.5 0v4.5h-4.5a.75.75 0 000 1.5h4.5v4.5a.75.75 0 001.5 0v-4.5h4.5a.75.75 0 000-1.5h-4.5v-4.5z" />
                    </svg>
                </span>
            </div>
        </div>

    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>

        </div>
    </Dialog>
</template>