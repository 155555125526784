<template>
    <Toast />
    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>
        <template #start>

            <div class="inline sm:hidden">
                <SplitButton label="Speichern" icon="pi pi-save" :model="items" />
            </div>
            <div class="hidden sm:flex sm:justify-items-start">
                <Button @click="save_changes" v-tooltip.bottom="'Änderungen Speichern'" label="Speichern" class="w-auto mr-4 p-button-success" icon="pi pi-save" :loading="loader" size="small" />
                <Divider layout="vertical" />
                <Button v-tooltip.bottom="'Preview Artikel'" @click="preview" label="Vorschlau" class="mr-2 p-button w-auto" icon="pi pi-eye" size="small" :disabled="disabled" />
                <Button v-tooltip.bottom="'Artikel schliessen'" @click="close_tab" label="Schliessen" class="mr-2 p-button-danger w-auto" icon="pi pi-times-circle" size="small" :disabled="disabled" />
            </div>

        </template>
        <template #end>
            <div class="hidden sm:inline-block">
                <Button type="button" icon="pi pi-refresh" aria-controls="overlay_menu" @click="reset_changes" />
            </div>
        </template>
    </Toolbar>

    <TabView ref="tabview4">
        <TabPanel>
            <template #header>
                <i class="pi pi-file mr-2"></i>
                <span class="mr-3">Meta</span>
            </template>
            <div class="md:flex flex-row md:my-5">
                <div class="w-auto md:w-2/4 m-2">

                    <Accordion class="accordion-custom" :activeIndex="0">
                        <AccordionTab>
                            <template #header>
                                <span class="mr-2 font-bold">Produkt</span>
                            </template>
                            <div class="mb-5">
                                <h3 class="mb-2">Artikel (<Button @click="cleanup_articles" label="Reset" style="padding: 0; margin: 0" link v-tooltip.top="'Verlinkte Artikel bei Fehlern entfernen und neu auswählen'" />)</h3>
                                <MultiSelect v-model="new_articles" :options="articles" optionLabel="title" optionValue="sku" placeholder="Artikel wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Artikel" filter>
                                    <template #header>
                                        <div class="py-2 px-3">
                                            <b>{{ new_articles ? new_articles.length : 0 }}</b> Artikel ausgewählt.
                                        </div>
                                    </template>
                                </MultiSelect>

                            </div>
                            <div class="my-5">
                                <h3 class="mb-2">Produktbeschreibung</h3>
                                <Editor editorStyle="width: 100%; min-height: 350px;" v-model="blog.text_content">
                                    <template v-slot:toolbar>
                                        <span class="ql-formats">
                                            <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                            <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                            <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                            <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                            <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                            <button v-tooltip.bottom="'chatGPT'" @click="chatGptDialog = true">
                                                <CloudIcon class="h-10 w-10" />
                                            </button>
                                        </span>
                                    </template>
                                </Editor>
                            </div>

                            <div class="my-5">
                                <h3 class="mb-2">Ähnliche Produkte</h3>
                                <Productselector @add-product="addProductslist" :list="add_productslist" />
                            </div>

                        </AccordionTab>
                    </Accordion>
                </div>


                <div class="w-auto md:w-2/4 m-2">
                    <div class="">
                        <Accordion class="accordion-custom" :activeIndex="0">
                            <AccordionTab>
                                <template #header>
                                    <span class="mr-2 font-bold">Metadaten</span>
                                </template>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <Dropdown id="blog_status" v-model="blog.status" :options="blog_status" optionLabel="name" optionValue="value" class="w-full" />
                                        <label for="blog_status">Status</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="title" class="w-full" v-model="blog.title" />
                                        <label for="title">Produktnamen</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <span class="p-float-label">
                                        <InputText id="title_de" class="w-full" v-model="blog.title_de" />
                                        <label for="title_de">Produktnamen (DE)</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <div class="my-2">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="swisswool_de" inputId="swisswoolde" :binary="true" />
                                            <label for="swisswoolde" class="ml-2">swisswool.de </label>
                                        </div>
                                    </div>

                                    <div class="my-2">
                                        <div class="flex align-items-center">
                                            <Checkbox v-model="swisswool_ch" inputId="swisswoolch" :binary="true" />
                                            <label for="swisswoolch" class="ml-2">swisswool.ch</label>
                                        </div>
                                    </div>
                                </div>

                                <div class=" bg-gray-100 rounded-md p-2">
                                    <div class="my-7">
                                        <Button label="Preisspanne generieren" :loading="pricespan_loading" icon="pi pi-calculator" size="small" class="" @click="generate_pricespan" :disabled="(new_articles && new_articles.length > 0) ? false : true" />
                                    </div>


                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputNumber id="min_price" class="w-full" v-model="blog.min_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                            <label for="min_price">Mindestpreis</label>
                                        </span>
                                    </div>

                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputNumber id="min_price_eur" class="w-full" v-model="blog.min_price_eur" inputId="currency-switzerland" mode="currency" currency="EUR" locale="de-CH" />
                                            <label for="min_price_eur">Mindestpreis EUR</label>
                                        </span>
                                    </div>

                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputNumber id="average_price" class="w-full" v-model="blog.average_price" inputId="currency-switzerland" mode="currency" currency="CHF" locale="de-CH" />
                                            <label for="average_price">Durchschnittpreis</label>
                                        </span>
                                    </div>
                                    <div class="my-7">
                                        <span class="p-float-label">
                                            <InputNumber id="average_price_eur" class="w-full" v-model="blog.average_price_eur" inputId="currency-switzerland" mode="currency" currency="EUR" locale="de-CH" />
                                            <label for="average_price_eur">Durchschnittpreis EUR</label>
                                        </span>
                                    </div>

                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_categories" v-model="new_blog_categories" :options="blog_categories" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Kategorien" />
                                        <label for="blog_categories">Kategorien</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="blog_subcategories" v-model="new_blog_subcategories" :options="blog_subcategories" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Subkategorien" />
                                        <label for="blog_subcategories">Subkategorien</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="data_qualities" v-model="new_data_quality" :options="blog_data_qualities" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Wollqualitäten" />
                                        <label for="data_qualities">Wollqualität</label>
                                    </span>
                                </div>

                                <div class="my-7">
                                    <span class="p-float-label">
                                        <MultiSelect id="data_colors" v-model="new_data_colors" :options="blog_data_colors" optionLabel="name" optionValue="value" class="w-full" filter :maxSelectedLabels="0" selectedItemsLabel="{0} Farben" />
                                        <label for="data_colors">Farben</label>
                                    </span>
                                </div>
                                <div class="my-7">
                                    <div class="flex align-items-center my-2">
                                        <Checkbox v-model="is_product_packaged" :binary="true" inputId="is_product_packaged" />
                                        <label for="is_product_packaged" class="ml-2">Verpackungseinheiten</label>
                                    </div>
                                    <span class="p-float-labe" v-show="is_product_packaged">
                                        <MultiSelect v-model="product_grouped" :options="articles" optionLabel="title" optionValue="sku" placeholder="Verpackungseinheiten wählen" :maxSelectedLabels="0" class="w-full md:w-20rem" selectedItemsLabel="{0} Artikel" filter :disabled="!is_product_packaged ? true : false" />
                                    </span>
                                </div>
                                <div class="flex flex-wrap" v-if="image_library.length > 0">

                                    <!-- <UploadImageButton :imagelibrary="image_library" @delete="delete_image" label="Hauptbild" :image="blog.header_image" :workflow_id="workflow_id" @uploaded-image="imageUploaded" image-type="header_image" :aspect-ratio="{ aspectRatio: 1.5 / 1 }" />

                                    <UploadImageButton v-for="i in 15" :key="i" :imagelibrary="image_library" :label="'Bild ' + i" :image="blog['add_image' + i]" :workflow_id="workflow_id" @uploaded-image="imageUploaded" :image-type="'add_image' + i" @delete="delete_image" :aspect-ratio="{ aspectRatio: 1.5 / 1 }" /> -->

                                    <!-- <Button @click="image_order_dialog = true" v-tooltip.bottom="'Bilder sortieren'" label="Bilder sortieren" class="mr-2 p-button w-auto" icon="pi pi-equals" /> -->
                                </div>
                                <div class="flex flex-wrap " v-if="image_library.length > 0 && blog['add_images']">
                                    <div v-for="(i, index) in Array.from({ length: blog['add_images'].length + 1 }) " :key="index" class="w-1/2">
                                        <UploadImageButton :key="index" :imagelibrary="image_library" :label="`Bild ${index+1}`" :image="blog['add_images'][index]" :workflow_id="workflow_id" @uploaded-image="imageUploaded2" :editable="false" :image-type="'add_images' + index" @delete="delete_image" :aspect-ratio="{ aspectRatio: 1.5 / 1 }" />
                                        <!-- <Button @click="delete_image2(index)" v-tooltip.bottom="'Bilder löschen'" v-if="blog['add_images'].length - index !== 0" icon="pi pi-times" severity="danger" text raised rounded aria-label="Cancel" /> -->
                                    </div>
                                </div>

                                <div v-if="blog['add_images'] && blog['add_images'].length > 0">
                                    <Button @click="image_order_dialog = true" v-tooltip.bottom="'Bilder sortieren/löschen'" label="Bilder sortieren/löschen" class="mr-2 p-button w-auto" icon="pi pi-equals" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-image mr-2"></i>
                                    <span class="mr-2 font-bold">Bilder Bibliotek</span>
                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(image_library.length) }}</span>
                                </template>
                                <ImageGallery :image-library="image_library" @update:imageLibrary="image_library = $event" />
                                <div>
                                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                    <div class="mt-4">
                        <Accordion class="accordion-custom mt-3">
                            <AccordionTab>
                                <template #header>
                                    <i class="pi pi-history mr-2"></i>
                                    <span class="mr-2 font-bold">Änderungsverlauf</span>

                                    <span class="inline-flex items-center rounded-md bg-gray-200 px-2 py-1 text-xs font-medium text-gray-600 ring-1 ring-inset ring-gray-500/10">{{ String(blog_changes.length) }}</span>
                                </template>
                                <div v-if="blog_changes">
                                    <div v-for="(change, index) of blog_changes" :key="index" class="my-8 bg-yellow-700/20 p-6 rounded-lg">

                                        <div class="text-xs">
                                            <Chip :label="change.user" icon="pi pi-user" class="m-1" />
                                            <Chip :label="swiss_date_time(change.date)" icon="pi pi-clock" class="m-2" />
                                        </div>
                                        <div class="bg-primary-100 text-white m-2 p-2 rounded-md">
                                            <span class=""> {{ change.message }}</span>
                                        </div>
                                    </div>
                                </div>
                            </AccordionTab>
                        </Accordion>
                    </div>
                </div>
            </div>
        </TabPanel>

    </TabView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <Dialog v-model:visible="blog_text_vorschlag_dialog" :style="{ width: '800px' }" header="Teaser Vorschlag" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            {{ blog_text_vorschlag }}
        </div>
    </Dialog>

    <Dialog v-model:visible="image_order_dialog" :style="{ width: '800px' }" header="Bilder ordnen" :modal="true" class="p-fluid">
        <DataTable class="mt-3" :value="blog['add_images']" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />

            <Column header="Name" class="min-w-48">
                <template #body="slotProps">
                    <span class=""><img :src="slotProps.data" class=" w-16 object-cover h-16"></span>
                </template>
            </Column>
            <Column header="Name" class="min-w-48">
                <template #body="slotProps">
                    <Button @click="delete_image2(slotProps.index)" v-tooltip.bottom="'Bilder löschen'" icon="pi pi-times" severity="danger" text raised rounded />
                </template>
            </Column>
        </DataTable>
    </Dialog>
    <!--------------------------------------------------->
    <!--------------- Edit Title Image ------------------>
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImageDialog" :style="{ width: '80%' }" header="Titel Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">

            <div v-if="edit_header_image == false" class="m-3">
                <img :src="blog.header_image" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>

            <span class="inline-block h-48 w-48 overflow-hidden rounded-full bg-gray-100 my-10" v-if="edit_header_image == false && !blog.header_image">
                <svg class="h-full w-full text-gray-300" fill="currentColor" viewBox="0 0 24 24">
                    <path d="M24 20.993V24H0v-2.996A14.977 14.977 0 0112.004 15c4.904 0 9.26 2.354 11.996 5.993zM16.002 8.999a4 4 0 11-8 0 4 4 0 018 0z" />
                </svg>
            </span>
            <Button v-if="edit_header_image == false" label="Bild Bearbeiten" @click="edit_header_image = true" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />

            <div v-if="edit_header_image == true" class="mt-3">
                <cropper ref="title_image_cropper" :src="blog.header_image" :auto-zoom="true" :auto-center="true" />
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>

            <div v-if="edit_header_image == true" class="mt-3">
                <Button label="Bild Speichern" @click="cropImage(title_image_cropper, 'header_image', 'image123')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>

    </Dialog>
    <!--------------------------------------------------->
    <!----------------- Preview Image ------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editTitleImagePreviewDialog" :style="{ width: '80%' }" header="Titel Vorschau-Bild editieren" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div v-if="edit_header_image_preview == false" class="mt-3">
                <img :src="blog.header_image_preview" class="shadow-2" style="width: 100%; height: 100%; object-fit: cover;" />
            </div>
            <Button v-if="edit_header_image_preview == false" label="Bild Bearbeiten" @click="edit_header_image_preview = true, blog.header_image_preview = blog.header_image" class="w-auto mr-3 mb-1 p-button" icon="pi pi-pencil" />
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <cropper ref="title_image_cropper_preview" :src="blog.header_image_preview" :auto-zoom="true" :auto-center="true" />
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <div v-if="image_library" class="flex">
                    <div v-for="(img, index) of image_library" class="flex" :key="index">
                        <img @click="blog.header_image_preview = img.image" :src="img.image" class="shadow-2 m-2" style="cursor: pointer; width: 80px; height: 80px; object-fit: cover;" />
                    </div>
                </div>
            </div>
            <div v-if="edit_header_image_preview == true" class="mt-3">
                <Button label="Bild Speichern" @click="crop_header_image(title_image_cropper_preview, 'header_image_preview')" class="w-auto mr-3 mb-1 p-success" icon="pi pi-save" />
            </div>
        </div>
    </Dialog>
    <!--------------------------------------------------->
    <!------------------- GPT Dialog -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>

<script setup>
import { ref, onMounted, watch, defineProps, defineEmits, inject, provide, shallowRef, computed } from 'vue'
import { CloudIcon } from '@heroicons/vue/20/solid';
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import { useConfirm } from "primevue/useconfirm"
import { Cropper, Preview } from 'vue-advanced-cropper'
import 'vue-advanced-cropper/dist/style.css';
//import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import StandardText from '@/core/components/Components/Builder-Blocks/Standard-Text.vue'
import StandardImage from '@/core/components/Components/Builder-Blocks/Standard-Image.vue'
import StandardTitle from '@/core/components/Components/Builder-Blocks/Standard-Title.vue'
import CTAButton from '@/core/components/Components/Builder-Blocks/Cta-Button.vue'
import HtmlBlock from '@/core/components/Components/Builder-Blocks/Html-Block.vue'
import QuoteBlock from '@/core/components/Components/Builder-Blocks/Quote-Block.vue'
import ImageText from '@/core/components/Components/Builder-Blocks/Image-Text.vue'
import ComparsionBlock from '@/core/components/Components/Builder-Blocks/Comparsion-Block.vue'
import QuotingLogo from '@/core/components/Components/Builder-Blocks/Quoting-Logo-Block.vue'
import ImageCols from '@/core/components/Components/Builder-Blocks/Image-Columns.vue'
import Markdown from '@/core/components/Components/Builder-Blocks/Markdown-Block.vue'
import FAQBlock from '@/core/components/Components/Builder-Blocks/FAQ-Block.vue'
import Simplestore from '@/core/var/Simplestore';
import { swiss_date_time, preview_dataset, checkDuplicate } from '@/core/var/tools'
import { uploadSingleImage } from '@/core/var/crud'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import VideoURL from '@/core/components/Components/Builder-Blocks/Video-URL.vue'
import YouTubeURL from '@/core/components/Components/Builder-Blocks/Youtube-URL.vue'
//import MainButton from '@/core/components/UI/Main-Button.vue'
import UploadImageButton from '@/core/components/Components/Main-UploadImage-Button2.vue';
import InputNumber from 'primevue/inputnumber';
import ImageGallery from '@/core/components/Components/Main-Image-Library.vue'
import Productselector from '../cruds/Product-Selector.vue'

const props = defineProps({
    redis_key: { type: String }
})

const content_blocks = ref([
    { name: 'standard_text', type: shallowRef(StandardText) },
    { name: 'standard_title', type: shallowRef(StandardTitle) },
    { name: 'standard_image', type: shallowRef(StandardImage) },
    { name: 'cta_button', type: shallowRef(CTAButton) },
    { name: 'html_block', type: shallowRef(HtmlBlock) },
    { name: 'quote_block', type: shallowRef(QuoteBlock) },
    { name: 'image_text', type: shallowRef(ImageText) },
    { name: 'comparsion_block', type: shallowRef(ComparsionBlock) },
    { name: 'quoting_logo', type: shallowRef(QuotingLogo) },
    { name: 'image_cols', type: shallowRef(ImageCols) },
    { name: 'video_url', type: shallowRef(VideoURL) },
    { name: 'youtube_url', type: shallowRef(YouTubeURL) },
    { name: 'markdown', type: shallowRef(Markdown) },
    { name: 'faq_block', type: shallowRef(FAQBlock) },
]);


const channel = ref(useCustomerStore().getCustomer.customer)
provide('channel', channel)
const SERVICE_URL = inject('SERVICE_URL')


const loader = ref(false)
const toast = useToast()
const confirm2 = useConfirm()
const imageUploadDialog = ref(false)
const editTitleImageDialog = ref(false)
const editTitleImagePreviewDialog = ref(false)
const edit_header_image_preview = ref(false)
const edit_header_image = ref(false)
const workflow_id = ref("")
const new_blog_categories = ref();
const blog = ref([])
const blog_categories = ref([])
const blog_subcategories = ref([]);
const new_blog_subcategories = ref(false)
const blog_tags = ref([])
const blog_sort = ref([])
const blog_status = ref([])
const users = ref([])
const blog_content = ref([])
const image_library = ref([])
const activeImageIndex = ref(0)
const displayGallery = ref(false)
const blog_changes = ref([])
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const blog_title = ref(null);
const articles = ref()
const new_articles = ref()
const blog_text_vorschlag = ref(null);
const blog_text_vorschlag_dialog = ref(false);
const title_image_cropper = ref();
const emits = defineEmits(['editorCallback'])
const title_image_cropper_preview = ref()
const disabled = ref(false);
const pricespan_loading = ref(false)
const is_product_packaged = ref()
const product_grouped = ref([])
const swisswool_de = ref(true)
const swisswool_ch = ref(true)

const new_data_colors = ref();
const new_data_quality = ref();
const image_order_dialog = ref(false)

const blog_data_qualities = ref([])
const blog_data_colors = ref([])

const add_productslist = ref([])

const items = ref([
    {
        label: 'Änderungen zurücksetzen',
        icon: 'pi pi-refresh',
        command: () => {
            reset_changes()
        }
    },
    {
        label: 'Vorschau',
        icon: 'pi pi-eye',
        command: () => {
            preview()
        }
    },
    {
        label: 'Schließen',
        icon: 'pi pi-times',
        command: () => {
            close_tab()
        }
    }

]);

const MODULE = 'products';

const endpoints = {
    get: `${MODULE}/get`,
    getsingle: `${MODULE}/get-single`,
    savesingle: `${MODULE}/save-single`,
    getsystem: `get-system`,
    getusers: `get-users`,
    getcategories: `${MODULE}/categories/get`,
    getsubcategories: `${MODULE}/subcategories/get`,
    getqualities: `${MODULE}/qualities/get`,
    getcolors: `${MODULE}/colors/get`,
    gettags: `${MODULE}/tags`,
    save: `${MODULE}/save`,
    uploadsingle: `upload-image`,
    uploadlibrary: `${MODULE}/upload-library-image`,
    preview: `${MODULE}/preview/save`,
    getarticles: `articles/get`,
    checktitle: `${MODULE}/check-title`,
    get_pricespan: `${MODULE}/get-pricespan`,
}

provide('upload_endpoint', endpoints.uploadsingle);

onMounted(async () => {
    loader.value = true;
    await getBlogs()
    await fetchSystemAndUsers();
    loader.value = false;
})

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});
watch(workflow_id, res => {
    provide('workflow_id', res)
})

const addProductslist = (products) => {
    add_productslist.value = products
}

const fetchSystemAndUsers = async () => {
    try {
        const systemRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsystem}`);
        const usersRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getusers}`);

        const categoriesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcategories}`);
        const subcategoriesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsubcategories}`);
        const qualitiesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getqualities}`);
        const colorsRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getcolors}`);
        const articlesRequest = axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getarticles}`);


        const [systemResponse, usersResponse, categoriesResponse, articlesResponse, subcategoriesResponse, qualitiesResponse, colorsResponse] = await Promise.all([systemRequest, usersRequest, categoriesRequest, articlesRequest, subcategoriesRequest, qualitiesRequest, colorsRequest]);

        articles.value = articlesResponse.data;
        blog_categories.value = categoriesResponse.data;
        blog_subcategories.value = subcategoriesResponse.data;
        blog_tags.value = systemResponse.data.blog_tags;
        blog_sort.value = systemResponse.data.blog_sort;
        blog_status.value = systemResponse.data.blog_status;
        users.value = usersResponse.data;

        blog_data_qualities.value = qualitiesResponse.data ? qualitiesResponse.data.filter(item => item.status === 'published') : [];
        blog_data_colors.value = colorsResponse.data ? colorsResponse.data.filter(item => item.status === 'published') : [];
        blog.value.title_de = blog.value.title_de ? blog.value.title_de : blog.value.title;
        swisswool_de.value = blog.value.swisswool_de == undefined ? true : blog.value.swisswool_de
        swisswool_ch.value = blog.value.swisswool_ch == undefined ? true : blog.value.swisswool_ch
    } catch (error) {
        console.error("Error fetching system and users data:", error);
    }
};

const preview = () => {
    const workload = {
        redis_key: blog.value.redis_key,
        content: blog.value.content,
        title: blog.value.title,
        teaser: blog.value.teaser,
        header_image: blog.value.header_image,
    };

    axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.preview}`, workload)
        .then(response => {
            preview_dataset(blog.value.slug, SERVICE_URL, MODULE)
        })
}

const generate_pricespan = async () => {

    const workload = new_articles.value
    pricespan_loading.value = true
    const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get_pricespan}`, { articles: workload });
    blog.value.price_range = Number(response.data.average_price).toFixed(2);
    blog.value.average_price = Number(response.data.average_price).toFixed(2);
    blog.value.min_price = Number(response.data.minimum_price).toFixed(2);
    blog.value.min_price_eur = Number(response.data.minimum_price_eur).toFixed(2);
    blog.value.average_price_eur = Number(response.data.average_price_eur).toFixed(2);
    pricespan_loading.value = false
}


const getBlogs = async () => {
    const payload = { redis_key: props.redis_key };
    try {
        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`, payload);
        const { data } = response;

        blog.value = data;
        add_productslist.value = data.add_productslist
        new_blog_categories.value = data.category;
        new_blog_subcategories.value = data.subcategory;
        new_data_colors.value = data.colors;
        new_data_quality.value = data.qualities;
        new_articles.value = data.articles;
        blog.value.category = data.category[0];
        workflow_id.value = data.workflow_id;
        blog_content.value = data.content;
        blog_title.value = data.title;
        blog_changes.value = data.changes;
        image_library.value = data.image_library;
        GPT_Primer(blog_title.value);

        product_grouped.value = data.group_products ? data.group_products : false;
        is_product_packaged.value = product_grouped.value.length > 0 ? true : false

    } catch (error) {
        console.error("Error fetching products data:", error);
    }
};

const GPT_Primer = (blog_title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen Text zum Thema ${blog_title}`,
            button_name: `Text automatsch erstellen`,
        },
        {
            question: `Erstelle einen Text zum Thema ${blog_title} und fasse ihn mit 100 Wörtern zusammen`,
            button_name: "Text-Zusammenfassung (100 Wörter)",
        },
    ];
}

const update_text_content = res => {
    blog.value.text_content = blog.value.text_content + res.chatGptAnswers;
    chatGptDialog.value = false;
}

const close_tab = () => {
    confirm2.require({
        message: 'Sind Sie sicher, dass Sie diesen Artikel schliessen wollen? Alle nicht gespeicherten Änderungen gehen verloren.',
        header: 'Artikel schliessen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Artikel schliessen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: () => {
            emits('editorCallback',
                {
                    "action": "close_tab",
                    "redis_key": props.redis_key
                }
            )
        }
    })
}

// const showGallery = (index) => {
//     activeImageIndex.value = index
//     displayGallery.value = true
// }

const update_template_data = (e) => {
    if (e.action == "save") {
        blog_content.value[e.index] = e.template_data
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gespeichert', life: 3000 })
    } else if (e.action == "delete") {
        blog_content.value.splice(e.index, 1)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt gelöscht', life: 3000 })
    } else if (e.action == "add") {
        blog_content.value.push(e.template_data)
        toast.add({ severity: 'success', summary: 'Erfolg', detail: 'Inhalt hinzugefügt', life: 3000 })
    }
}

const save_changes = async () => {
    loader.value = true;
    const { value } = blog;

    const checkTitle = await checkDuplicate(blog.value.title, blog.value.workflow_id, `${channel.value}/${endpoints.checktitle}`)

    if (checkTitle.msg == true) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Der Titel existiert bereits', life: 3000 })
        loader.value = false;
        return;
    }

    if (value.min_price == 'NaN' || value.min_price == null) {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Fehler bei der Preiseingabe, bitte wählen Sie mindestens einen eingepreisten Artikel aus, und klicken Sie Preispanne generieren', life: 8000 })
        loader.value = false;
        return;
    }
    
    if (value.title_de == null || value.title_de == "") {
        toast.add({ severity: 'error', summary: 'Fehler', detail: 'Ein Produktname (DE) muss angegeben werden', life: 8000 })
        loader.value = false;
        return;
    }

    value["content"] = blog_content.value;
    value["changes"] = blog_changes.value;
    value["image_library"] = image_library.value;
    value["category"] = new_blog_categories.value;
    value["subcategory"] = new_blog_subcategories.value;
    value["qualities"] = new_data_quality.value;
    value["colors"] = new_data_colors.value;
    value["articles"] = new_articles.value;
    value['swisswool_de'] = swisswool_de.value ? swisswool_de.value : false;
    value['swisswool_ch'] = swisswool_ch.value ? swisswool_ch.value : false;
    value['add_productslist'] = add_productslist.value


    if (is_product_packaged.value == true) {
        value['group'] = is_product_packaged.value
        value['group_products'] = product_grouped.value
    } else {
        value['group'] = false
        value['group_products'] = false
    }

    try {
        const response = await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.savesingle}`,
            value
        );

        const { status } = response.data;

        if (status === "success" || status === "titlechanged") {
            toast.add({
                severity: "success",
                summary: `${value.title} gespeichert`,
                detail: "Änderungen wurden erfolgreich gespeichert",
                life: 3000,
            });
            loader.value = false;
        }

        // tmp hack
        value["category"] = value.category[0];
    } catch (error) {
        console.error(error);
    }
};

const reset_changes = async () => {
    confirm2.require({
        message: 'Wollen Sie wirklich alle Änderungen verwerfen?',
        header: 'Änderungen verwerfen',
        rejectLabel: 'Nein',
        acceptLabel: 'Ja, Änderungen verwerfen',
        acceptClass: 'p-button-danger',
        icon: 'pi pi-exclamation-triangle',
        accept: async () => {
            try {
                const payload = { redis_key: props.redis_key };
                const response = await axios.post(
                    `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.getsingle}`,
                    payload
                );
                blog.value = response.data;
            } catch (error) {
                console.error(error);
            }
        },
    });
};

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        console.log(e);
        blog.value['add_images'] = e.value
    }
}

const crop_header_image = async (image, image_type) => {

    await uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 3000,
        });

        blog.value.header_image = res.data.image_url;
        edit_header_image.value = false;
    });
}


const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, blog.value.workflow_id, channel.value, endpoints.uploadsingle, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type, reference) => {
    loader.value = true;
    UploadImage(image, image_type).then(res => {
        toast.add({
            severity: "success",
            summary: "Erfolg",
            detail: "Bild erfolgreich hochgeladen",
            life: 2000,
        });
        [reference].value = res;
        loader.value = false;
    });
}

const libraryUploadGetData = (res) => {
    blog.value.image_library.push({ image: res });
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const openEditDialog = res => {
    editTitleImageDialog.value = res;
}

const imageUploaded = res => {
    blog.value[res.type] = res.image;
}
// new logic
const imageUploaded2 = res => {
    blog.value['add_images'].push(res.image);
}

const delete_image = res => {
    blog.value[res.type] = null;
    disabled.value = false;
    imageUploadDialog.value = false;
}
const cleanup_articles = () => {
    new_articles.value = [];
}
const delete_image2 = (index) => {
    blog.value['add_images'].splice(index, 1);
}
</script>
