<template>
    <Toast />
    <ConfirmDialog />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden sm:flex">
                <Button v-show="changes != 0" v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" />
                <Button v-show="changes > 0" @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2" icon="pi pi-save" />

                <Button @click="setNew" label="Erstellen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" />
                <!-- <Button @click="imageUploadDialog = true" label="Bilder hochladen" class="w-auto mr-2 p-button" v-tooltip.top="'Bilder in die Datensatz Bibliothek hochladen'" icon="pi pi-upload" /> -->
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown" currentPageReportTemplate="{first} bis {last} von {totalRecords}">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column>
        <!-- <Column field="image" style="width: 100px">
            <template #body="slotProps">
                <img :src="slotProps.data.avatar" width="64" class="shadow-2 mr-2" style="vertical-align: middle; width: 64px; height: 64px; object-fit: cover; border-radius: 5%; box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16), 0 3px 6px rgba(0, 0, 0, 0.23)" />
            </template>
        </Column> -->
        <Column field="name" header="name">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.name }}</span>
            </template>
        </Column>
        <Column field="code" header="Code">
            <template #body="slotProps">
                <span class=" font-semibold">{{ slotProps.data.code }}</span>
            </template>
        </Column>
                <Column field="used" header="Angewandt">
                <template #body="slotProps">
                    <span>{{ slotProps.data.used.length }}</span>
                </template>
            </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false" @click="editDialog = true, data_name = slotProps.data.name, data_code = slotProps.data.code, data_rabattTyp = slotProps.data.type, data_rabatt = slotProps.data.discount, data_index = slotProps.index, data_status = slotProps.data.status,
                    data_endDate = slotProps.data.end, data_startDate = slotProps.data.start,
                    data_minOrder = slotProps.data.minimum, data_autoPromo = slotProps.data.automated, data_workflow_id = slotProps.data.workflow_id, data_maxUsage = slotProps.data.usage, data_selectedProducts = slotProps.data.products, data_excludedProducts = slotProps.data.excluded" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4">
        
        <div class="w-full mx-auto">
            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-1/2 px-3">
                    <!-- Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                        <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>


                    <!-- Rabatt Typ -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="rabattTyp">Rabatt Typ</label>
                        <Dropdown v-model="data_rabattTyp" :options="rabattTypes" id="rabattTyp" optionLabel="label" optionValue="value" class="w-full" />
                    </div>

                    <!-- Rabatt -->
                    <div class="mb-4" v-if="rabattVisible">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="rabatt">Rabatt {{ data_rabattTyp == 'percent' ? '%' : 'CHF' }}</label>

                        <InputNumber v-model="data_rabatt" id="rabatt" mode="decimal" :min="0" :max="100" :disabled="rabattDisabled" class="appearance-none  w-full text-gray-700 leading-tight" v-if="data_rabattTyp == 'percent'" :minFractionDigits="0" :maxFractionDigits="2" locale="de-CH"  />

                        <InputNumber id="price" class="appearance-none w-full  text-gray-700 leading-tight" v-model="data_rabatt" inputId="rabatt" mode="currency" currency="CHF" locale="de-CH" v-if="data_rabattTyp == 'CHF'"  />
                    </div>

                    <!-- Start Datum -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="startDate">Start Datum </label>
                        <Calendar v-model="data_startDate" id="startDate" :showIcon="true" dateFormat="dd.mm.yy" class="w-full" />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="endDate">Schluss Datum </label>
                        <Calendar v-model="data_endDate" id="endDate" :showIcon="true" dateFormat="dd.mm.yy" class="w-full" />
                    </div>
                    <div class="mb-4 mt-3">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="autoPromo">Automatisierte Promo Code</label>
                        <div class="flex">
                            <div v-for="option in yesNoOptions" :key="option.value" class="mr-4 flex items-center">
                                <RadioButton v-model="data_autoPromo" :value="option.value" inputId="autoPromo" name="autoPromo" />
                                <label :for="option.label" class="ml-2">{{ option.label }}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Column 2 -->
                <div class="w-1/2 px-3">

                    <!-- Code -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="code">Code *</label>
                        <InputText v-model="data_code" id="code"  class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>

                    <!-- Minimum Bestellung -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="minOrder">Minimum Bestellung *</label>
                        <!-- <InputText v-model="data_minOrder" id="minOrder" type="number" step="1" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " /> -->
                        <InputNumber class="appearance-none w-full  text-gray-700 leading-tight" v-model="data_minOrder" id="minOrder" mode="currency" currency="CHF" locale="de-CH"   />
                    </div>

                    <!-- Maximale Nutzung -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="maxUsage">Maximale Nutzung *</label>
                        <InputText v-model="data_maxUsage" id="maxUsage" type="number" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " />
                    </div>


                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="selectedProducts">Ausgewählte Produkte</label>
                        <MultiSelect v-model="data_selectedProducts" :options="products" id="selectedProducts" optionLabel="title" optionValue="sku" placeholder="Artikel wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Artikel" filter>
                            <template #header>
                                <div class="py-2 px-3">
                                    <b>{{ data_selectedProducts ? data_selectedProducts.length : 0 }}</b> Artikel ausgewählt.
                                </div>
                            </template>
                        </MultiSelect>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="excludedProducts">Ausgeschlossene Produkte</label>
                        <MultiSelect v-model="data_excludedProducts" :options="products" id="excludedProducts" optionLabel="title" optionValue="sku" placeholder="Artikel wählen" :maxSelectedLabels="0" class="w-full" selectedItemsLabel="{0} Artikel" filter>
                            <template #header>
                                <div class="py-2 px-3">
                                    <b>{{ data_excludedProducts ? data_excludedProducts.length : 0 }}</b> Artikel ausgewählt.
                                </div>
                            </template>
                        </MultiSelect>
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="status">Status</label>
                        <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" class="w-full" />
                    </div>

                </div>



            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_big" label="Speichern" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>

    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Promocode hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="w-full mx-auto">
            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-1/2 px-3">
                    <!-- Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                        <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>
                </div>

                <!-- Column 2 -->
                <div class="w-1/2 px-3">
                    <!-- Code -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="code">Code *</label>
                        <InputText v-model="data_code" id="code" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>

                </div>

            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_entry" label="Speichern" @click="new_dataset()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>



    </Dialog>
    <!--------------------------------------------------->
    <!---------------- Image Library -------------------->
    <!--------------------------------------------------->
    <!-- <Dialog v-model:visible="imageUploadDialog" :style="{ width: '800px' }" header="Bilder hochladen" :modal="true" class="p-fluid">
        <div class="col-12 formgrid grid">
            <div class="field col-12 mt-3">
                <FileUpload name="demo[]" :customUpload="true" @uploader="upload_library_image" :multiple="true" accept="image/*" :maxFileSize="10000000" chooseLabel="Bilder auswählen" uploadLabel="Hochladen" cancelLabel="Abbrechen">
                    <template #empty>
                        <div>
                            <div class="flex justify-content-center"><i class="pi pi-upload" style="font-size: 4rem; color: #dee2e6;"></i></div>
                            <div class="flex justify-content-center mt-2">Hochladen per Drag & Drop</div>
                        </div>
                    </template>
                </FileUpload>
            </div>
        </div>
    </Dialog> -->

    <Dialog v-model:visible="chatGptDialog" :style="{ width: '80%' }" header="Inhalte mit AI erstellen" :modal="true" class="p-fluid">
        <ChatGPT :questions="chatGptQuestions" @chat-callback="update_text_content" />
    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import ChatGPT from '@/core/components/Components/Main-GPT.vue';
import { CloudIcon } from '@heroicons/vue/20/solid';
import { useCustomerStore } from '@/core/store/CustomerStore'
import 'vue-advanced-cropper/dist/style.css';
import { slugify, getRandomId, toUnixTime } from '@/core/var/tools'

// Data

const data_code = ref('');
const data_rabattTyp = ref('CHF');
const data_rabatt = ref(0);
const data_startDate = ref(null);
const data_endDate = ref(null);
const data_minOrder = ref(0);
const data_autoPromo = ref(false);
const data_maxUsage = ref(0);
const data_selectedProducts = ref([]);
const data_excludedProducts = ref([]);
const products = ref([]);

const rabattTypes = [
    { label: 'CHF', value: 'CHF' },
    { label: 'Prozent', value: 'percent' },
    { label: 'Gratisversand', value: 'FreeShipping' }
];

const yesNoOptions = [
    { label: 'Ja', value: true },
    { label: 'Nein', value: false }
];

const rabattVisible = computed(() => data_rabattTyp.value !== 'FreeShipping');
const rabattDisabled = computed(() => data_rabattTyp.value === 'FreeShipping');

const formValid_entry = computed(() => data_name.value && data_code.value);

const formValid_big = computed(() => {
   let res =  data_name.value && data_code.value
   if(data_rabattTyp.value == 'percent' || data_rabattTyp.value == 'CHF'){
        if(!data_rabatt.value){
            res = false
        }
   }
   return res
});



const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const dataset = ref([])
const data_name = ref(null)
const data_status = ref(null)
const data_index = ref();
const data_content = ref(null)
const data_workflow_id = ref(null)
const chatGptQuestions = ref([])
const chatGptDialog = ref(false)
const changes = ref(0)
const save_text = ref("Änderungen speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()

const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])

const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

// const formValid_big = ref(false);

// watch(
//     () => [data_name.value, data_code.value, data_minOrder.value, data_maxUsage.value, data_rabattTyp.value, data_rabatt.value],
//     ([name, code, minOrder, maxUsage, rabattTyp, rabatt]) => {
//         let res = name && code && minOrder && maxUsage;
//         if (rabattTyp === 'percent' || rabattTyp === 'CHF') {
//             if (!rabatt) {
//                 res = false;
//             }
//         }
//         formValid_big.value = res;
//     }
// );
const slug = ref(useCustomerStore().getCustomer.customer);

const module = 'promocodes';

const endpoints = {
    get: `${module}/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `${module}/upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    getcategories: `${module}/categories`,
    gettags: `${module}/tags`,
    getusers: "get-users",
    save: `${module}/save`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {
    try {
        const [dataResponse, productsResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`),

            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/articles/get`)
        ]);
        dataset.value = dataResponse.data;
        products.value = productsResponse.data;
    } catch (error) {
        console.error(error);
    }
}




const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});

watch(data_startDate, res => {
    console.log(res)
})

const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    if (status_value == "published") {
        return "Aktiv"
    } else if (status_value == "draft") {
        return "Inaktiv"
    }
}

const setNew = () => {
    newDialog.value = true;
    data_name.value = ''
    data_code.value = ''
    data_startDate.value = false
    data_endDate.value = false
}

const save_dataset = async (index) => {

        dataset.value[index]["workflow_id"] = data_workflow_id.value;
        dataset.value[index]["status"] = data_status.value
        dataset.value[index]["name"] = data_name.value
        dataset.value[index]["code"] = data_code.value
        dataset.value[index]["type"] = data_rabattTyp.value
        dataset.value[index]["discount"] = data_rabattTyp.value == 'CHF' || data_rabattTyp.value == 'percent' ?  data_rabatt.value : 0;
        dataset.value[index]["start"] = data_startDate.value;
        dataset.value[index]["end"] = data_endDate.value;
        dataset.value[index]["minimum"] = data_minOrder.value;
        dataset.value[index]["automated"] = data_autoPromo.value;
        dataset.value[index]["usage"] = data_maxUsage.value;
        dataset.value[index]["products"] = data_selectedProducts.value;
        dataset.value[index]["excluded"] = data_excludedProducts.value;
        dataset.value[index]["used"] = [];
        dataset.value[index]["status"] = data_status.value;
        
        incrementSaves();
}

const new_dataset = async () => {


    //const res = await checkCode(data_code.value);
    const res = false

    if (res === false) {
        const slug = slugify(data_name.value);

        const append = {
            "workflow_id": getRandomId(6),
            "slug": slug,
            "name": data_name.value,
            "code": data_code.value,
             "type": data_rabattTyp.value,
            // "discount": data_rabatt.value,
            // "start": data_startDate.value,
            // "end": data_endDate.value,
            "minimum": 0,
             "automated": false,
             "usage": 0,
            // "products": data_selectedProducts.value,
            // "excluded": data_excludedProducts.value,
             "used": [],
            "status": "draft",
        }

        dataset.value.unshift(append)

        force_save.value = true
        incrementSaves();
        newDialog.value = false
    } else {
        toast.add({ severity: 'error', summary: 'Code', detail: 'Code bereits in Verwendung', life: 3000 });
    }
}


const checkCode = async code => {

    try {
        const payload = {
            code: code
        };

        const response = await axios.post(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel}/promocodes/check-code`, payload);

        return response.data;

    } catch (error) {
        console.error('Error checking duplicate:', error);
        throw error;
    }
}

const delete_dataset = (id) => {
    const newt = dataset.value.filter(dataset => dataset.workflow_id !== id);
    dataset.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${slug.value}/${endpoints.save}`,
            dataset.value);

        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        changes.value = 0;
        save_text.value = "Änderungen speichern";
        force_save.value = false;

    } catch (error) {
        console.error(error);
    }
};

const GPT_Primer = (title) => {
    chatGptQuestions.value = [
        {
            question: `Erstelle einen FAQ-Antort auf die Frage zum Thema ${title}`,
            button_name: "Antwort automatisch erstellen",
        }
    ];
}

const update_text_content = res => {
    data_content.value = data_content.value + res.chatGptAnswers;
    chatGptDialog.value = false;
}
</script>
