<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import { uploadSingleImage } from '@/core/var/crud'
import MenuButton from './Components/Menu-Button.vue'
import DataView from './Components/Data-View.vue'
import 'vue-advanced-cropper/dist/style.css'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from './Components/Modal-Menue.vue'
import ImageEditor from './Components/Image-Editor.vue'
import { TabGroup, TabList, Tab, TabPanels, TabPanel } from '@headlessui/vue'

const BLOCK_NAME = "image_cols";
const NAME = 'Bilder 2 Spalten';
const channel = ref(useCustomerStore().getCustomer.customer);

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true },
    aspectRatio: { type: Object, required: false, default: () => { } }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields

const text_image_caption1 = ref(props.data.text_image_caption1)
const text_image_caption2 = ref(props.data.text_image_caption2)
const text_image1 = ref(props.data.text_image1)
const text_image2 = ref(props.data.text_image2)

const text_headline = ref(props.data.text_headline)


const template = ref(BLOCK_NAME)
const image = ref(props.data.image)
const image_upload_url = ref()
const croppy1 = ref(null)
const croppy2 = ref(null)
const edit1 = ref(false)
const edit2 = ref(false)

// functions
const edit_block = () => {
    editDialog.value = true
    edit1.value = false
    edit2.value = false
    image.value = props.data.image
}

const save_block = () => {



    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption1": text_image_caption1.value,
                "text_image_caption2": text_image_caption2.value,
                "text_headline": text_headline.value,
                "text_image1": text_image1.value,
                "text_image2": text_image2.value
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_image_caption1": "",
                "text_image_caption2": "",
                "text_headline": "",
                "text_image1": "",
                "text_image2": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}

const crop_image1 = res => {
    croppy1.value = res;
    //console.log(res.getResult())
   // text_image1.value = res.getResult().canvas.toDataURL();
   text_image1.value = res.getResult().image.src; 
   upload_image1(croppy1)
    edit1.value = false
}
const crop_image2 = res => {
    croppy2.value = res;
    //console.log(res.getResult().image.src)
    //text_image2.value = res.getResult().canvas.toDataURL();
    text_image2.value = res.getResult().image.src;
    upload_image2(croppy2)
    edit2.value = false
}

const upload_image1 = async (crp) => {
    await uploadSingleImage(crp.value, props.index, props.workflow_id, channel.value, 'upload-image', true).then(res => {
        text_image1.value = res.data.image_url;
    });
}
const upload_image2 = async (crp) => {
    await uploadSingleImage(crp.value, props.index, props.workflow_id, channel.value, 'upload-image', true).then(res => {
        text_image2.value = res.data.image_url;
    });
}
const toggleDialog = () => {
    editDialog.value = false
}


</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-image" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div class="container mx-auto px-2 py-4">
            <h2 class="text-xl font-semibold mb-2 text-center">{{ text_headline }}</h2>
            <div class="flex">
                <div>
                    <img :src="text_image1" alt="" class="w-1/2">
                    <span class=" text-start text-xs block">{{ text_image_caption1 }}</span>
                </div>
                <div>
                    <img :src="text_image2" alt="" class="w-1/2">
                    <span class=" text-start text-xs block">{{ text_image_caption2 }}</span>
                </div>
            </div>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>

            <div class="my-3 px-5">
                <label for="headline">Überschrift</label>
                <InputText v-model="text_headline" id="headline" type="text" :autoResize="true" />
            </div>

            <div class="p-5">
                <TabGroup vertical>
                    <TabList >
                        <Tab as="template" v-slot="{ selected }" class="px-4 py-1">
                            <button :class="{ 'p-1 rounded-t-md bg-primary-300 text-white': selected, 'bg-gray-100 text-black': !selected }">
                                Bild 1
                            </button>
                        </Tab>
                        <Tab as="template" v-slot="{ selected }" class="px-4 py-1">
                            <button :class="{ 'p-1 rounded-t-md bg-primary-500 text-white': selected, 'bg-gray-100 text-black': !selected }">
                                Bild 2
                            </button>
                        </Tab>

                    </TabList>
                    <TabPanels>
                        <TabPanel>
                            <div class="px-3 bg-gray-100 rounded-br-md">
                                <!-- <h3 class=" text-xl">Bild 1</h3> -->
                                <ImageEditor :edit="edit1" :image_library="image_library" :image="text_image1" @update="crop_image1" :stencil-props="props.aspectRatio" />
                                <label for="text_image_caption">Bildunterschrift 1</label>
                                <InputText v-model="text_image_caption1" id="text_image_caption1" type="text" />
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <div class="px-3 bg-gray-100">
                                <!-- <h3 class="text-xl">Bild 2</h3> -->
                                <ImageEditor :edit="edit2" :image_library="image_library" :image="text_image2" @update="crop_image2" :stencil-props="props.aspectRatio" />
                                <label for="text_image_caption">Bildunterschrift 2</label>
                                <InputText v-model="text_image_caption2" id="text_image_caption2" type="text" />
                            </div>
                        </TabPanel>

                    </TabPanels>
                </TabGroup>
            </div>
        </div>
    </Dialog>
</template>