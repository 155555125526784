<script setup>
import { ref, defineProps, defineEmits } from 'vue'
import 'vue-advanced-cropper/dist/style.css'
import { useCustomerStore } from '@/core/store/CustomerStore'
import MenuButton from './Components/Menu-Button.vue'
import ModalButtons from './Components/Modal-Menue.vue'
import DataView from './Components/Data-View.vue'

const BLOCK_NAME = "2cols_block";
const NAME = '2 Spalten Block';
const channel = ref(useCustomerStore().getCustomer.customer);

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: false },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])

// fields
const template = ref(BLOCK_NAME)


const html_content_col1 = ref(props.data.html_content_col1);
const html_content_col2 = ref(props.data.html_content_col2);
const title_col1 = ref(props.data.title_col1);
const title_col2 = ref(props.data.title_col2);

const text_headline = ref(props.data.text_headline)
const edit = ref(false)

// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
}

const save_block = () => {

    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "html_content_col1": html_content_col1.value,
                "html_content_col2": html_content_col2.value,
                "title_col1": title_col1.value,
                "title_col2": title_col2.value,
                "text_headline": text_headline.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "html_content_col1": "",
                "html_content_col2": "",
                "title_col1": "",
                "title_col2": "",
                "text_headline": "",
            },
            "action": "add",
            "index": props.index
        }
    )
}
const toggleDialog = () => {
    editDialog.value = false
}
</script>

<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-sliders-h" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div class="container mx-auto px-2 py-2">
            <h2 class="text-xl font-semibold mb-2 text-center">{{ text_headline }}</h2>
            <div class="flex">
                <div class="bg-white p-4 rounded w-1/2">
                    <div class="text-sm">Spalte 1</div>
                    <h3 class=" font-semibold">{{ title_col1 }}</h3>
                    <span v-html="html_content_col1" class=" text-sm"></span>
                </div>
                <div class="bg-white p-4 rounded w-1/2">
                    <div class="text-sm">Spalte 2</div>
                    <h3 class=" font-semibold">{{ title_col2 }}</h3>
                    <span v-html="html_content_col2" class=" text-sm"></span>
                </div>
            </div>
        </div>
    </DataView>

    <!--------------------------------------------------->
    <!------------------- Edit Block -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">
        <div class="">
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <div class="my-3">
                <label for="text_headline">Überschrift</label>
                <InputText v-model="text_headline" id="text_text_headline" type="text" :autoResize="true" />
            </div>
            <div class="flex">
                <div class="my-3 mr-2 w-1/2">
                    <div class="text-base font-bold">Spalte 1</div>
                    <div class="my-5">
                        <InputText v-model="title_col1" id="title_col1" type="text" :autoResize="true" />
                    </div>
                    <Editor editorStyle="" class="h-80 w-full" v-model="html_content_col1">
                        <template v-slot:toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            </span>
                        </template>
                    </Editor>
                </div>
                <div class="my-3 mr-2 w-1/2">
                    <div class="text-base font-bold">Spalte 2</div>
                    <div class="my-5">
                        <InputText v-model="title_col2" id="title_col2" type="text" :autoResize="true" />
                    </div>
                    <Editor editorStyle="" class="w-full  h-80" v-model="html_content_col2">
                        <template v-slot:toolbar>
                            <span class="ql-formats">
                                <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                            </span>
                        </template>
                    </Editor>
                </div>
            </div>
        </div>
    </Dialog>
</template>