<template>
    <div class="my-4">
        <Dropdown v-model="selected_product" :options="products" filter optionLabel="title" placeholder="Produkt hinzufügen" class="w-full md:w-14rem" @change="addProductToList" />
    </div>
    <div class="my-4" v-if="products_list.length > 0">
        <!-- {{ products_list }} -->
        <DataTable :value="products_list" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
            <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="true" />
            <Column>
                <template #body="slotProps">
                    <img :src="slotProps.data.header_image" :alt="slotProps.data.title" class="w-24 h-24 object-cover rounded-md border border-gray-300 shadow-sm" />
                </template>
            </Column>
            <Column field="title" header="Produkt">
                <template #body="slotProps">
                    <span class=" text-sm">{{ slotProps.data.title }}</span>
                </template>
            </Column>
            <Column field="average_price" header="Preis">
                <template #body="slotProps">
                    <span class=" text-sm">{{ formatToSwissCurrency(slotProps.data.average_price) }}</span>
                </template>
            </Column>
            <Column>
                <template #body="slotProps">
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="deleteProduct(slotProps.data)"></Button>
                </template>
            </Column>
        </DataTable>
    </div>
</template>

<script setup>
import { ref, onMounted, watch } from 'vue';
import axios from 'axios';
import { useCustomerStore } from '@/core/store/CustomerStore';
import { formatToSwissCurrency } from '@/core/var/tools';

const props = defineProps({
    list: {
        type: Array,
        default: () => []
    }
});

const emit = defineEmits(['add-product']);

const customerStore = useCustomerStore();
const channel = ref(customerStore.getCustomer.customer);
const products = ref([]);
const selected_product = ref(null);
const products_list = ref([]);

watch(() => props.list, (newList) => {
    products_list.value = newList;
}, { immediate: true });

onMounted(async () => {
    await load_products();
});

const load_products = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/products/get`);
        products.value = response.data;
    } catch (error) {
        console.error('Error loading products:', error);
    }
};

const addProductToList = () => {
    if (selected_product.value) {
        products_list.value.push(selected_product.value);
        emit('add-product', products_list.value);
        selected_product.value = null;
    }
};

const onRowReorder = (event) => {
    if (event.dragIndex !== event.dropIndex) {
        products_list.value = event.value;
        emit('add-product', products_list.value);
    }
};

const deleteProduct = (product) => {
    const index = products_list.value.findIndex(p => p.id === product.id);
    if (index > -1) {
        products_list.value.splice(index, 1);
        emit('add-product', products_list.value);
    }
};
</script>