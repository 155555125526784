<template>
    <div class="flex gap-1 my-1">
        <div><Button icon="pi pi-plus" class="p-button-sm" @click="showDialog('add')" /></div>
        <div>
            <Dropdown v-model="mode" :options="modes" id="modes" optionLabel="name" optionValue="value" class="w-full" />
        </div>

        <!-- <Button icon="pi pi-upload" class="p-button-sm" @click="emitItems" /> -->
    </div>


    <DataTable :value="items" paginator :rows="20" tableStyle="min-width: 100%" rowReorder @rowReorder="onRowReorder" :rowClass="rowClass">
        <Column rowReorder style="width:3em"></Column>
        <Column field="mode" header="Mode">
            <template #body="slotProps">
                <Chip :label="slotProps.data.mode" :class="`chip-${slotProps.data.mode}`" />
            </template>
        </Column>
        <Column field="title" header="Name"></Column>
        <Column field="description" header="Beschreibung">
            <template #body="slotProps">
                <span v-html="slotProps.data.description"></span>
            </template>
        </Column>
        <Column field="image" header="Bild-URL">
            <template #body="slotProps">
                <img :src="slotProps.data.image" class=" w-48" v-if="slotProps.data.image" />
                <div v-else>Bild URL</div>
            </template>
        </Column>
        <Column field="logo" header="Logo">
            <template #body="slotProps">
                <img :src="slotProps.data.logo" class=" w-48" v-if="slotProps.data.logo" />
                <div v-else>Logo URL</div>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <div class="flex flex-row gap-2">
                    <Button icon="pi pi-pencil" class="p-button-rounded p-button-primary p-button-sm" @click="showDialog('edit', slotProps.data)" outlined />
                    <Button icon="pi pi-image" class="p-button-rounded p-button-primary p-button-sm" @click="showImageDialog(slotProps.data)" outlined />
                    <Button icon="pi pi-trash" class="p-button-rounded p-button-warning p-button-sm" @click="deleteItem(slotProps.data)" outlined />
                </div>
            </template>
        </Column>
    </DataTable>


    <Dialog v-model:visible="dialogVisible" :header="dialogHeader" class=" w-1/3">

        <div class="my-3" v-if="mode == 'partner'">
            <label for="mode">Partner</label>
            <Dropdown v-model="selectedPartner" :options="props.partners" @change="loadPartnerData" id="partner" optionLabel="title" optionValue="workflow_id" class="w-full" filter />
        </div>
        <div class="my-3" v-else>
            <label for="mode">Produkte</label>
            <Dropdown v-model="selectedProduct" :options="props.products" @change="loadProductData" id="products" optionLabel="title" optionValue="workflow_id" class="w-full" filter />
        </div>

        <div class="my-3">
            <label for="title">Titel</label>
            <InputText id="title" v-model="item.title" class=" w-full" />
        </div>
        <div class="my-3">
            <label for="description">Beschreibung</label>

            <Editor editorStyle="width: 100%; height: 150px" id="description" v-model="item.description">
                <template v-slot:toolbar>
                    <span class="ql-formats">
                        <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                        <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                        <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                        <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                        <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                    </span>
                </template>
            </Editor>
        </div>

        <div class="my-3">
            <label for="image">Image URL</label>
            <div v-if="item.image">
                <Image :src="item.image" class=" w-24" preview />
            </div>
            <InputText id="image" v-model="item.image" class=" w-full" />
        </div>
        <div class="my-3">
            <label for="logo">Logo URL</label>
            <div v-if="mode == 'partner'"><img :src="item.logo" class=" w-24" />
                <InputText id="logo" v-model="item.logo" class=" w-full" />
            </div>
            <div v-else><img src="https://swisswool.ch/assets/logos/logo.png" class=" w-24" /></div>
        </div>
        <Button label="Speichern" @click="saveItem" />
    </Dialog>

    <Dialog v-model:visible="imageDialogVisible" header="Bild bearbeiten" class=" w-2/3">

        <ImageEditor :edit="true" :image_library="image_library" :image="item.image" @update="change_image" @delete="remove_image" />

        <div class="my-3">
            <label for="image">Image URL</label>
            <div v-if="item.image">
                <Image :src="item.image" class=" w-64" preview />
            </div>
            <InputText id="image" v-model="item.image" class=" w-full" />
        </div>
        <Button label="Speichern" @click="saveImageItem" />
    </Dialog>
</template>


<script setup>
import { ref, defineEmits, defineProps, computed } from 'vue';
import Image from 'primevue/image';
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'
import { uploadSingleImage } from '@/core/var/crud'

const emit = defineEmits(['get']);
const props = defineProps({
    sliders: {
        type: Array,
        default: () => [],
    }, partners: {
        type: Array,
        default: () => [],
    }, products: {
        type: Array,
        default: () => [],
    },
    image_library: {
        default: '',
    },
    workflow_id: {
        default: '',
    },
});

const items = ref(props.sliders);
const mode = ref('partner');
const item = ref({ title: '', description: '', image: '', logo: '' });
const dialogVisible = ref(false);
const imageDialogVisible = ref(false);
const dialogHeader = ref('');
const product = ref(false);

const selectedProduct = ref(null);
const selectedPartner = ref(null);
const modes = ref([{ name: 'Partner', value: 'partner' }, { name: 'Produkt', value: 'product' }]);

const showDialog = (type, data = { workflow_id: '', slug: '', title: '', description: '', image: '', logo: '' }) => {
    if (type === 'edit') {
        item.value = { ...data };
    } else {
        item.value = { title: '', description: '', image: '', logo: '' };
        if (mode.value === 'product') {
            item.value.logo = 'https://swisswool.ch/assets/logos/logo.png';
        }
    }

    dialogVisible.value = true;
    dialogHeader.value = type === 'add' ? 'Slider Hinzufügen' : 'Editieren';
};

const showImageDialog = (data) => {
    item.value = { ...data };
    imageDialogVisible.value = true;
};

const sortedItems = computed(() => {
    return items.value.slice().sort((a, b) => b.id - a.id);
});

const saveItem = () => {
    if (item.value.title && item.value.description) {
        if (item.value.id) {
            const index = items.value.findIndex(i => i.id === item.value.id);
            if (index !== -1) {
                items.value[index] = { ...item.value };
            }
        } else {
            item.value.id = Date.now();
            items.value.push({ ...item.value, mode: mode.value });
        }
        dialogVisible.value = false;
    }
    emitItems();
};

const saveImageItem = () => {
    const index = items.value.findIndex(i => i.id === item.value.id);
    if (index !== -1) {
        items.value[index] = { ...item.value };
    }
    imageDialogVisible.value = false;
    emitItems();
};

const deleteItem = (data) => {
    const index = items.value.findIndex(i => i.id === data.id);
    if (index !== -1) {
        items.value.splice(index, 1);
    }
    emitItems();
};

const onRowReorder = (event) => {
    const { value } = items;
    const draggedItem = value[event.dragIndex];

    value.splice(event.dragIndex, 1);
    value.splice(event.dropIndex, 0, draggedItem);
    items.value = [...value];
    emitItems();
};

const emitItems = () => {
    emit('get', items.value);
};

const rowClass = (data) => {
    return [{ 'bg-yellow-100': data.mode === 'partner' }];
};

const loadProductData = async () => {
    if (selectedProduct.value) {
        const product = props.products.find(product => product.workflow_id === selectedProduct.value);

        if (product) {
            item.value.workflow_id = product.workflow_id;
            item.value.slug = product.slug;
            item.value.title = product.title;
            item.value.description = product.text_content || ' ';
            item.value.image = product.header_image;
        }
    }
};

const loadPartnerData = async () => {
    if (selectedPartner.value) {
        const partner = props.partners.find(product => product.workflow_id === selectedPartner.value);
        if (partner) {
            item.value.workflow_id = partner.workflow_id;
            item.value.slug = partner.slug;
            item.value.title = partner.title;
            item.value.description = partner.text_content || ' ';
            item.value.image = partner.header_image;
            item.value.logo = partner.partner_logo;
        }
    }
};

const change_image = async (res) => {
    const result = await cropImage(res, 'produktwelt_main')
    item.value.image = result;
}

const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, props.workflow_id, 'dashboard', 'upload-image', true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type) => {
    const res = await UploadImage(image, image_type);
    return res;
}
</script>
<style scoped>
.chip-partner {
    @apply bg-orange-200;
}
.chip-product {
    @apply bg-indigo-200 text-white;
}   
</style>