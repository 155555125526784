<script setup>
import { ref, defineProps, defineEmits, onMounted } from 'vue'
import axios from 'axios'

import MenuButton from '@/core/components/Components/Builder-Blocks/Components/Menu-Button.vue'
import DataView from '@/core/components/Components/Builder-Blocks/Components/Data-View.vue'
import { useCustomerStore } from '@/core/store/CustomerStore'
import ModalButtons from '@/core/components/Components/Builder-Blocks/Components/Modal-Menue.vue'
import { formatToSwissCurrency } from '@/core/var/tools'

const BLOCK_NAME = "add_stories";
const NAME = 'Stories';
const channel = ref(useCustomerStore().getCustomer.customer);
const template = ref(BLOCK_NAME)

// local variables
const editDialog = ref(false)
// define props
const props = defineProps({
    template_type: { type: String, required: true },
    index: { type: Number, required: false },
    data: { type: Object, required: true },
    image_library: { type: Object, required: true },
    workflow_id: { type: String, required: true }
})
// define emits
const emits = defineEmits(['blockCallback'])



const headline = ref(props.data.text_headline)
const stories_list = ref(props.data.stories_list)

const edit = ref(false)
const stories = ref([])
const selected_product = ref()


// functions
const edit_block = () => {
    editDialog.value = true
    edit.value = false
}

const save_block = () => {

    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_headline": headline.value,
                "stories_list": stories_list.value,
            },
            "action": "save",
            "index": props.index
        }
    )
    editDialog.value = false
}

const delete_block = () => {
    emits('blockCallback',
        {
            "action": "delete",
            "index": props.index
        }
    )
    editDialog.value = false
}

const add_block = () => {
    emits('blockCallback',
        {
            "template_data": {
                "template": template.value,
                "text_headline": "",
                "stories_list": [],
            },
            "action": "add",
            "index": props.index
        }
    )
}


const toggleDialog = () => {
    editDialog.value = false
}

onMounted(async () => {
    await load_stories()
})

const load_stories = async () => {
    try {
        const [storiesResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/stories/get`),
            //axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/articles/get`)
        ]);
        stories.value = storiesResponse.data;
        console.log(stories.value);
    } catch (error) {
        console.error(error);
    }
}

const addProductToList = () => {
    stories_list.value.push(selected_product.value);
}

const onRowReorder = (e) => {
    if (e.dragIndex != e.dropIndex) {
        stories_list.value = e.value
    }
}
const deleteProduct = (product) => {
    const index = stories_list.value.indexOf(product);
    if (index > -1) {
        stories_list.value.splice(index, 1);
    }
};
</script>
<template>
    <!--------------------------------------------------->
    <!------------------ Menu View ---------------------->
    <!--------------------------------------------------->
    <MenuButton v-if="props.template_type == 'menu'" :add_block="add_block" button="pi-file-edit" :name="NAME" />

    <!--------------------------------------------------->
    <!------------------ Data View ---------------------->
    <!--------------------------------------------------->
    <DataView v-if="props.template_type == 'data'" :edit_block="edit_block" :name="NAME">
        <div>
            <h2 class="text-xl font-semibold mb-2 text-center">{{ headline }}</h2>
        </div>
        <div class="overflow-x-auto">
            <table class="min-w-full leading-normal">
                <tbody>
                    <template v-for="(product, index) in stories_list.slice(0, 3)" :key="index">
                        <tr>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                <img :src="product.header_image" :alt="product.header_image" class="w-16 h-16 object-cover rounded-md border border-gray-300 shadow-sm" />
                            </td>
                            <td class="px-5 py-5 border-b border-gray-200 bg-white text-sm">
                                {{ product.title }}
                            </td>
                        </tr>
                    </template>
                </tbody>
            </table>
            <div class=" text-sm m-1">
                {{ stories_list.length > 3 ? 'und ' + (stories_list.length - 3) + ' weitere Stories' : '' }}
            </div>
        </div>
    </DataView>
    <!--------------------------------------------------->
    <!------------------- Dialogs ----------------------->
    <!--------------------------------------------------->

    <!--------------------------------------------------->
    <!------------------- Edit Image -------------------->
    <!--------------------------------------------------->
    <Dialog v-model:visible="editDialog" :style="{ width: '80%' }" header="Block bearbeiten" :modal="true" class="p-fluid">

        <div>
            <ModalButtons :saveBlock="save_block" :deleteBlock="delete_block" :toggleDialog="toggleDialog" />
        </div>
        <div>
            <div class="my-3">
                <label for="headline">Überschrift</label>
                <InputText v-model="headline" id="headline" type="text" :autoResize="true" />
            </div>
            <div class="my-3">
                <Dropdown v-model="selected_product" :options="stories" filter optionLabel="title" placeholder="Story hinzufügen" class="w-full md:w-14rem" @change="addProductToList" />
            </div>
            <div class="my-3">
                <DataTable :value="stories_list" :rows="20" responsiveLayout="scroll" :rowHover="true" @rowReorder="onRowReorder">
                    <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="true" />
                    <Column>
                        <template #body="slotProps">
                            <img :src="slotProps.data.header_image" :alt="slotProps.data.header_image" class="w-24 h-24 object-cover rounded-md border border-gray-300 shadow-sm" />
                        </template>
                    </Column>
                    <Column field="title" header="Story"></Column>
                    <Column>
                        <template #body="slotProps">
                            <Button icon="pi pi-trash" class="p-button-rounded p-button-danger p-button-outlined" @click="deleteProduct(slotProps.data)"></Button>
                        </template>
                    </Column>
                </DataTable>
            </div>
        </div>
    </Dialog>
</template>