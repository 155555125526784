<template>
    <Toast />
    <ConfirmDialog />

    <LibraryUpload v-model:visible="imageUploadDialog" :path="endpoints.uploadlibrary" :slug="channel" @data="libraryUploadGetData" @finished="libraryUploadidFinished" />

    <Toolbar>

        <template #start>
            <div class="inline sm:hidden">
                <div class="">
                    <SplitButton label="Neu" icon="pi pi-plus" @click="setNew" :model="items" />
                </div>
            </div>
            <div class="hidden md:flex">


                <div v-if="changes > 0"><Button v-tooltip.bottom="'Änderungen zurücksetzen'" @click="reset_changes" class="mr-2 p-button-danger" icon="pi pi-refresh" /></div>
                <div v-if="changes > 0"> <Button @click="save_changes" v-tooltip.bottom="'Änderungen speichern'" :label="save_text" class="w-auto  p-button-success mx-2 mr-3" icon="pi pi-save" /></div>
                <div v-if="changes == 0"> <Button @click="setNew" label="Erstellen" class="w-auto mr-2 p-button" v-tooltip.top="'Ein neuer Datensatz erfassen'" icon="pi pi-plus" /></div>
                <div>
                    <Button @click="imageUploadDialog = true" v-tooltip.bottom="'Bilder in die Bibliotek hochladen'" label="Bilder hochladen" class="mr-2 p-button w-auto" icon="pi pi-upload" />
                </div>
                <div>
                    <Button @click="image_libraryDialog = true" v-tooltip.bottom="'Bilder Bibliotek'" :label="`Bilder Bibliotek (${image_library.length})`" class="mr-2 p-button w-auto" icon="pi pi-image" />
                </div>
            </div>
        </template>


    </Toolbar>
    <Toolbar class="mt-2">
        <template #start>
            <span class="p-input-icon-left">
                <i class="pi pi-search" />
                <InputText v-model="filter_dataset['global'].value" placeholder="Suche" style="width: 400px" class="mr-2" />
            </span>
        </template>
    </Toolbar>
    <!--------------------------------------------------->
    <!------------------ DataTable ---------------------->
    <!--------------------------------------------------->
    <DataTable class="mt-3" v-model:filters="filter_dataset" :value="dataset" :rows="20" responsiveLayout="scroll" :rowHover="true" :rowsPerPageOptions="[20, 50, 100]" @rowReorder="onTeamRowReorder" :paginator="true" paginatorTemplate="CurrentPageReport FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown"
        currentPageReportTemplate="{first} bis {last} von {totalRecords}" @row-dblclick="handleRowDoubleClick">
        <Column :rowReorder="true" headerStyle="width: 3rem" :reorderableColumn="false" />
        <Column field="status" header="Status">
            <template #body="{ data, field }">
                <span class="inline-flex items-center rounded-full bg-yellow-200 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10" v-if="data[field] == 'draft'" :label="get_status(data[field])" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>

                <span class="inline-flex items-center rounded-full bg-green-50 px-2 py-1 text-xs font-medium text-green-900 ring-1 ring-inset ring-green-600/20" v-if="data[field] == 'published'" v-tooltip.top="'Beitrag ist Publiziert'">{{ get_status(data[field]) }}</span>
            </template>
        </Column>
        <Column field="Bild" header="Bild">
            <template #body="slotProps">
                <img :src="slotProps.data.image" v-if="slotProps.data.image" class="w-20 h-20 object-cover rounded-lg shadow-md">
                <div v-else></div>
            </template>
        </Column>
        <Column field="name" header="name">
            <template #body="slotProps">
                <span class="">{{ slotProps.data.name }}</span>
            </template>
        </Column>

        <Column field="section" header="Sektion">
            <template #body="slotProps">
                <span class="">{{ getNameByValue(slotProps.data.section) }}</span>
            </template>
        </Column>
        <Column field="sliders" header="Slider">
            <template #body="slotProps">
                <span class="">{{ !slotProps.data.sliders ? '' : slotProps.data.sliders.length }}</span>
            </template>
        </Column>
        <Column>
            <template #body="slotProps">
                <Button v-if="force_save == false" @click="handleEditClick(slotProps)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz bearbeiten'" icon="pi pi-pencil" />
                <Button v-if="force_save == false" @click="delete_dataset(slotProps.data.workflow_id)" class="p-button-rounded p-button-text" v-tooltip.top="'Datensatz löschen'" icon="pi pi-trash" />
            </template>
        </Column>
    </DataTable>

    <div class="flex items-center justify-center m-16" v-show="loader">
        <LoadingSpinner size="12"></LoadingSpinner>
    </div>


    <!------------------- Dialogs ----------------------->
    <Dialog v-model:visible="editDialog" header="Datensatz bearbeiten" :modal="true" class="w-auto md:w-3/4" maximizable>

        <div class="w-full mx-auto">
            <div class="flex flex-wrap">

                <!-- Column 1 -->
                <div class="w-1/2 px-3">
                    <!-- Name -->
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                        <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="sections">Sektion</label>
                        <Dropdown v-model="data_section" :options="sections" id="sections" optionLabel="name" editable optionValue="value" class="w-full" />
                    </div>

                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="cta_title">CTA Titel</label>
                        <InputText v-model="data_cta_title" id="cta_title" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="cta_url">CTA URL</label>
                        <InputText v-model="data_cta_url" id="cta_url" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
                    </div>

                </div>

                <!-- Column 2 -->
                <div class="w-1/2 px-3">
                    <div class="mb-4">
                        <label class="block text-gray-700 text-sm font-bold mb-2" for="status">Status</label>
                        <Dropdown v-model="data_status" id="status" :options="status" optionLabel="label" optionValue="value" class="w-full" />
                    </div>

                    <div class="mb-4">
                        <h3 class="mb-2 font-semibold">Beschreibung</h3>
                        <Editor editorStyle="width: 100%; min-height: 200px;" v-model="data_description">
                            <template v-slot:toolbar>
                                <span class="ql-formats">
                                    <button class="ql-bold" v-tooltip.bottom="'Bold'"></button>
                                    <button class="ql-italic" v-tooltip.bottom="'Italic'"></button>
                                    <button class="ql-underline" v-tooltip.bottom="'Underline'"></button>
                                    <button class="ql-link" v-tooltip.bottom="'Hyperlink'"></button>
                                    <button class="ql-clean" v-tooltip.bottom="'Clear Format'"></button>
                                </span>
                            </template>
                        </Editor>
                    </div>
                </div>
                <div class="my-6 w-full">
                    <h3 class="mb-2 font-semibold text-xl">Bild</h3>
                    <ImageEditor :edit="true" :image_library="image_library" :image="data_image" @update="change_image" @delete="remove_image" :stencil-props="{ aspectRatio: 1.5 / 1 }" />
                </div>
                <div class="my-6 w-full">
                    <h3 class="mb-2 font-semibold text-xl">Sliders</h3>
                    <SlidersCRUD @get="getarray" :sliders="data_sliders" :partners="partners" :products="products" :image_library="image_library" :workflow_id="data_workflow_id" />
                </div>
            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_big" label="Speichern" @click="save_dataset(data_index), editDialog = false" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="editDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>

    </Dialog>

    <!------------------ New ---------------------->
    <Dialog v-model:visible="newDialog" header="Slider hinzufügen" :modal="true" class=" w-full mx-3 md:w-3/4">

        <div class="w-full mx-auto">

            <div class="mb-4">
                <label class="block text-gray-700 text-sm font-bold mb-2" for="name">Name *</label>
                <InputText v-model="data_name" id="name" class="appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight " required />
            </div>

            <!-- Buttons -->
            <div class="flex items-center justify-between mt-4">
                <Button :disabled="!formValid_entry" label="Speichern" @click="new_dataset()" class="w-auto mr-2 mb-1 p-button-success" icon="pi pi-save" />
                <Button label="Abbrechen" @click="newDialog = false" class="w-auto mr-3 mb-1 p-button-danger" icon="pi pi-times" />
            </div>
        </div>
    </Dialog>

    <Dialog v-model:visible="image_libraryDialog" header="Bilder Bibliotek" :modal="true" class=" w-full mx-3 md:w-3/4">
        <ImageGallery :image-library="image_library" @update:imageLibrary="updateImageLibrary" />
    </Dialog>
</template>


<script setup>
import { ref, onMounted, watch, inject, computed } from 'vue'
import { useToast } from 'primevue/usetoast'
import axios from 'axios'
import Simplestore from '@/core/var/Simplestore';
import LoadingSpinner from '@/core/components/UI/Loading-Spinner.vue';
import { FilterMatchMode } from 'primevue/api'
import { useCustomerStore } from '@/core/store/CustomerStore'
import 'vue-advanced-cropper/dist/style.css';
import { slugify, getRandomId } from '@/core/var/tools'
import SlidersCRUD from './cruds/Swisswool-Sliders.vue'
import LibraryUpload from '@/core/components/Components/Main-Library-Upload.vue'
import ImageGallery from '@/core/components/Components/Main-Image-Library.vue'
import ImageEditor from '@/core/components/Components/Builder-Blocks/Components/Image-Editor.vue'
import { uploadSingleImage } from '@/core/var/crud'

// Data
const toast = useToast()
const SERVICE_URL = inject('SERVICE_URL');
const dataset = ref([])
const data_name = ref(null)
const data_status = ref(null)
const data_index = ref();
const data_workflow_id = ref(null)
const changes = ref(0)
const save_text = ref("Speichern")
const editDialog = ref(false)
const newDialog = ref(false)
const force_save = ref(false)
const loader = ref(false)
const disabled = ref()
const data_section = ref(false)
const data_description = ref('')
const data_cta_title = ref('')
const data_cta_url = ref('')
const data_sliders = ref([])
const partners = ref([])
const products = ref([])
const imageUploadDialog = ref(false)
const image_library = ref([])
const data_image = ref(null)
const image_libraryDialog = ref(false)

const sections = ref([
    { value: 'interior', name: 'Interior' },
    { value: 'akustik', name: 'Akustik' },
    { value: 'bauen', name: 'Bauen' },
    { value: 'sport', name: 'Sport' },
    { value: 'garten', name: 'Garten' },
    { value: 'teppich', name: 'Teppich' },
    { value: 'alltag', name: 'Alltag' },
    { value: 'bett', name: 'Bett' }
]);

const getNameByValue = (value) => {
    const section = sections.value.find(section => section.value === value);
    return section ? section.name : null;
};

const formValid_entry = computed(() => data_name.value);
const formValid_big = computed(() => {
    return true
});

const status = ref([
    { label: 'Aktiv', value: 'published' },
    { label: 'Inaktiv', value: 'draft' }
])

const filter_dataset = ref({ 'global': { value: null, matchMode: FilterMatchMode.CONTAINS } })

const channel = ref(useCustomerStore().getCustomer.customer);

const module = 'produktwelt';

const endpoints = {
    get: `${module}/get`,
    getImagelib: `${module}/get-image-library`,
    uploadImage: `upload-image`,
    uploadLibImage: `${module}/upload-library-image`,
    getsystem: "get-system",
    getcategories: `${module}/categories`,
    gettags: `${module}/tags`,
    getusers: "get-users",
    uploadlibrary: `${module}/upload-library-image`,
    save: `${module}/save`
}


onMounted(async () => {
    loader.value = true;
    await bootstrap();
    loader.value = false;
})


const bootstrap = async () => {
    try {
        const [dataResponse, partnersResponse, productsResponse, sectionsResponse, imagelibraryResponse] = await Promise.all([
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/partners/get`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/products/get`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/produktwelt/sections/get`),
            axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/produktwelt/get-image-library`),
        ])
        dataset.value = dataResponse.data;
        partners.value = partnersResponse.data;
        products.value = productsResponse.data;
        sections.value = sectionsResponse.data;
        image_library.value = imagelibraryResponse.data;
    } catch (error) {
        console.error(error);
    }
}


const items = ref([
    {
        label: save_text,
        icon: 'pi pi-refresh',
        disabled: changes.value > 0 ? false : true,
        command: () => {
            save_changes();
        }
    },
    {
        label: 'Zurücksetzen',
        icon: 'pi pi-times',
        disabled: changes.value != 0 ? false : true,
        command: () => {
            reset_changes();
        }
    },
]);

watch(changes, res => {
    items.value[0].disabled = res > 0 ? false : true;
    items.value[1].disabled = res != 0 ? false : true;
});

watch(loader, res => {
    Simplestore.spinner = res;
    disabled.value = res;
});


const reset_changes = async () => {
    try {
        const response = await axios.get(`${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.get}`);
        dataset.value = response.data;
        changes.value = 0;
        save_text.value = "Speichern";
        force_save.value = false;
    } catch (error) {
        console.error(error);
    }
};

const onTeamRowReorder = (e) => {
    dataset.value = e.value
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
    force_save.value = true
    toast.add({ severity: 'success', summary: 'Reihenfolge geändert', detail: 'Die Reihenfolge wurde erfolgreich geändert', life: 3000 })
}

const get_status = (status_value) => {
    if (status_value == "published") {
        return "Aktiv"
    } else if (status_value == "draft") {
        return "Inaktiv"
    }
}

const setNew = () => {
    newDialog.value = true;
    data_name.value = ''
}

const save_dataset = async (index) => {

    dataset.value[index]["workflow_id"] = data_workflow_id.value;
    dataset.value[index]["slug"] = slugify(data_name.value);
    dataset.value[index]["status"] = data_status.value
    dataset.value[index]["name"] = data_name.value
    dataset.value[index]["status"] = data_status.value;
    dataset.value[index]["section"] = data_section.value;
    dataset.value[index]["description"] = data_description.value;
    dataset.value[index]["cta_title"] = data_cta_title.value;
    dataset.value[index]["cta_url"] = data_cta_url.value;
    dataset.value[index]["sliders"] = data_sliders.value;
    dataset.value[index]["image"] = data_image.value;
    incrementSaves();
}

const new_dataset = async () => {

    const slug = slugify(data_name.value);

    const append = {
        "workflow_id": getRandomId(6),
        "slug": slug,
        "name": data_name.value,
        "status": "draft",
    }

    dataset.value.unshift(append)
    force_save.value = true
    incrementSaves();
    newDialog.value = false
}


const delete_dataset = (id) => {
    const newt = dataset.value.filter(dataset => dataset.workflow_id !== id);
    dataset.value = newt;
    incrementSaves();
}

const incrementSaves = () => {
    changes.value = changes.value + 1
    save_text.value = "Speichern (" + changes.value + ")"
}

const save_changes = async () => {
    try {
        await axios.post(
            `${process.env.VUE_APP_NEURAXIS_API_MAIN}/${channel.value}/${endpoints.save}`,
            dataset.value);

        toast.add({
            severity: "success",
            summary: "Änderungen gespeichert",
            detail: "Die Änderungen wurden erfolgreich gespeichert",
            life: 3000
        });

        changes.value = 0;
        save_text.value = "Speichern";
        force_save.value = false;

    } catch (error) {
        console.error(error);
    }
};


const update_text_content = res => {
    data_content.value = data_content.value + res.chatGptAnswers;
    chatGptDialog.value = false;
}



function handleEditClick(slotProps) {
    editDialog.value = true;
    data_index.value = slotProps.index;
    data_workflow_id.value = slotProps.data.workflow_id;
    data_name.value = slotProps.data.name;
    data_status.value = slotProps.data.status;
    data_section.value = slotProps.data.section;
    data_description.value = slotProps.data.description;
    data_cta_title.value = slotProps.data.cta_title;
    data_cta_url.value = slotProps.data.cta_url;
    data_sliders.value = slotProps.data.sliders;
    data_image.value = slotProps.data.image;
}

function handleRowDoubleClick(event) {
    handleEditClick(event);
}

const getarray = (res) => {
    data_sliders.value = res;
    toast.add({ severity: 'success', summary: 'Erfolgreich!', detail: `Daten (${res.length}) wurden übernommen`, life: 3000 });
}

const libraryUploadidFinished = (res) => {
    if (res === true) {
        imageUploadDialog.value = false;
    }
}

const libraryUploadGetData = (res) => {
    image_library.value.push(res);
    bootstrap();
}

const change_image = async (res) => {
    const result = await cropImage(res, 'produktwelt_main')
    data_image.value = result;
}

const UploadImage = (image, image_type) => {
    return new Promise((resolve, reject) => {
        uploadSingleImage(image, image_type, data_workflow_id.value, 'dashboard', endpoints.uploadImage, true).then((res) => {
            resolve(res.data.image_url);
        }).catch(err => {
            reject(err);
        });
    });
}

const cropImage = async (image, image_type) => {
    const res = await UploadImage(image, image_type);
    return res;
}
const updateImageLibrary = async (event) => {
    image_library.value = event;
    try {
        await axios.post(process.env.VUE_APP_NEURAXIS_API_MAIN + '/swisswool/produktwelt/set-image-library', image_library.value);
    } catch (error) {
        console.error(error);
    }
};

const remove_image = (res) => {
    data_image.value = null;
};
</script>
